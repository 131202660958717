import React from "react";
import { ISegment } from "../model";
import { CalculateWithRoud, RoundNumber } from "../utils";

/**
 * TEST
 * @param param0 
 */
const  ViewComponent:React.FC<{targetValue:number, percentage:number, segmentsToCalculates:ISegment[]}>= ({targetValue,percentage, segmentsToCalculates}) => {

  const percentageTargetValue = RoundNumber((targetValue / 100) * percentage);

  const recalculate = [...segmentsToCalculates];

  const[percentageSummary, valuesSummary, calculates] = CalculateWithRoud(percentageTargetValue,recalculate)
  
  return (
    <div>
      <h1>{percentage} %  -&gt; {percentageTargetValue.toFixed(2)} €</h1>
      <table className="result-table">
        <tbody>
        <tr className="header-row">
            <td></td>
            <td>%</td>
            <td>€</td>            
            <td></td>
            <td></td>
            <td></td>
          </tr>
          {calculates.map((f) => (
            <tr className={f.correction ? "corected-row" : ""} key={f.name}>
              <td>{f.name}</td>
              <td>{f.percentage}</td>
              <td className="calculated-value">{f.value}</td>
              <td>{f.decimalValue}</td>
              <td>{f.originalValue}</td>
              <td>{f.correction}</td>
            </tr>
          ))}
           <tr className="footer-row">
            <td></td>            
            <td>{percentageSummary.toFixed(2)}</td>
            <td>{valuesSummary.toFixed(2)}</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>      
    </div>
  );
}

export default ViewComponent;
