import React, { useEffect, useState } from "react";
import { ISegment } from "../model";
import { CalculateWithRoud } from "../utils";
import ViewComponent from "../components/ViewComponent";


const calculatesDefault: ISegment[] = [
  { name: "nemocenské", percentage: 1.4, value: 8.0 },
  { name: "starobné", percentage: 14, value: 80.0 },
  { name: "invalidné", percentage: 3, value: 17.14 },
  { name: "v nezamestnanosti", percentage: 1, value: 5.71 },
  { name: "rezervný fond", percentage: 4.75, value: 27.14 },
  { name: "úrazové", percentage: 0.8, value: 4.57 },
];
 

function BudgetaryCalculator() {
  const [targetValue, setTargetValue] = useState(142.57);
  const [calculates, setCalculates] = useState<ISegment[]>(calculatesDefault);
  const [percentageSummary, setPercentageSummary] = useState(0);
  const [valuesSummary, setValuesSummary] = useState(0);

 
 
  const inputTargetValueHanldeChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const numValue = +e.target.value; 
    const[percentageSummary,valuesSummary, newCalculates] = CalculateWithRoud(numValue, calculates);
    setTargetValue(numValue);
    setCalculates(newCalculates);
    setPercentageSummary(percentageSummary);
    setValuesSummary(valuesSummary);
  };

  const inputPercentageHandleChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const appMode = e.currentTarget.getAttribute("data-appmode");
    const newPercentageValue = e.target.value;

    const element = calculates.find((f) => f.name === appMode);
    if (element) {
      element.percentage = +newPercentageValue;
    }

    const[percentageSummary,valuesSummary, newCalculates] = CalculateWithRoud(targetValue, calculates);
    setCalculates(newCalculates);
    setPercentageSummary(percentageSummary);
    setValuesSummary(valuesSummary);

    console.log(appMode);
  };

//  useEffect(()=>{
//   const[percentageSummary, newCalculates] = CalculateWithRoud(targetValue, calculates);
//   setCalculates(newCalculates);
//   setPercentageSummary(percentageSummary);
//  },[targetValue,calculates]);
 useEffect(()=>{
  const[percentageSummary,valuesSummary ,newCalculates] = CalculateWithRoud(targetValue, calculates);
  setCalculates(newCalculates);
  setPercentageSummary(percentageSummary);
  setValuesSummary(valuesSummary);
 },[]);

  return (
    <div className="budgetary-calculator">
      <div className='input-value'>
      <span>Vstup:</span>{" "}
      <input
        onChange={inputTargetValueHanldeChange}
        type="number"
        value={targetValue}
      /> €
    </div>     
      <table className="result-table">
        <tbody>
        <tr className="header-row">
            <td></td>
            <td>%</td>
            <td>€</td>            
            <td></td>
            <td></td>
            <td></td>
          </tr>
          {calculates.map((f) => (
            <tr className={f.correction ? "corected-row" : ""} key={f.name}>
              <td>{f.name}</td>
              <td>
                <input
                  value={f.percentage}
                  onChange={inputPercentageHandleChange}
                  data-appmode={f.name}
                  type="number"
                /> 
              </td>              
              <td className="calculated-value">{f.value}</td>
              <td>{f.decimalValue}</td>
              <td>{f.originalValue}</td>
              <td>{f.correction}</td>
            </tr>
          ))}
          <tr className="footer-row">
            <td></td>            
            <td>{percentageSummary.toFixed(2)}</td>
            <td>{valuesSummary.toFixed(2)}</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>    
    </div>
  );
}

export default BudgetaryCalculator;
