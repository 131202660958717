import React, { useEffect, useState } from "react";
import "./App.css";
import BudgetaryCalculator from "./views/BudgetaryCalculator";
import Project1Calculator from "./views/Project1Calculator";
import { NavLink, Route, Switch } from "react-router-dom";

function App() {

  const handlePrintButtonClick = ()=>{
    window.print();
  }

  return (
    <div className="App">
      <div className="the-navigation">
        <nav>
          <ul>
            <li>
              <NavLink to="/" exact>
                Rozpoctova kalkulacka :)
              </NavLink>
            </li>
            <li>
              <NavLink to="/project1" exact>
                Projekt 1
              </NavLink>
            </li>
            <li>
              <a href="javescript:"  onClick={handlePrintButtonClick}>Vytlacit</a>
            </li>
          </ul>
        </nav>
      </div>
      <hr />
      <div className="contet">
        <Switch>
          <Route path="/" component={BudgetaryCalculator} exact />
          <Route path="/project1" component={Project1Calculator} />
        </Switch>
      </div>
    </div>
  );
}

export default App;
