import { ISegment } from "../model";

export function RoundNumber(input:number) {
    return Math.round(input * 100) / 100;
  }
  

  export function CalculateWithRoud(targetValue:number,calculateSegments:ISegment[]):[number, number, ISegment[]] {
    let diference: number = 0;
  
    const calculates = calculateSegments.map<ISegment>((f) => {
      return { name: f.name, percentage: f.percentage, value:f.value };
    });
  
    const percentageSummary = calculates
      .map((c) => c.percentage)
      .reduce((a, b) => a + b);
  
    //calculate value
    calculates.forEach((element) => {
      element.value = RoundNumber(
        (targetValue * element.percentage) / percentageSummary
      );
    });
  
    //get decimapPart
    calculates.forEach((element) => {
      let decimalPartString = element.value.toString().split(".")[1];
      if (!decimalPartString) decimalPartString = "0";
      element.decimalValue = +decimalPartString;
    });
  
    //calculate summary
    const valuesSummary = RoundNumber(
      calculates.map((c) => c.value).reduce((a, b) => a + b)
    );
    console.log(targetValue, valuesSummary);
    if (targetValue !== valuesSummary) {
      diference = RoundNumber(targetValue - valuesSummary);
  
      //find place to correction
      const decimalArray = calculates
        .filter((f) => f.decimalValue)
        .map((c) => c.decimalValue as number);
      const minDecimalPart = Math.min(...decimalArray);
  
      console.log("MIN", minDecimalPart);
  
      const itemToCorrection: ISegment = calculates.filter(
        (item) => item.decimalValue === minDecimalPart
      )[0];
  
      itemToCorrection.correction = diference;
      itemToCorrection.originalValue = itemToCorrection.value;
      itemToCorrection.value = RoundNumber(
        itemToCorrection.value + itemToCorrection.correction
      );
  
      // calculates.forEach((item)=>{
      //   if(item.decimalValue && item.decimalValue>0)
      //   {
      //      const lastNumber:number =  +item.decimalValue.toString().slice(-1);
      //      console.log(lastNumber);
  
      //   }
      // });
    }
  
    const recalculatedValuesSummary = RoundNumber(
      calculates.map((c) => c.value).reduce((a, b) => a + b)
    );
  
    return [percentageSummary,recalculatedValuesSummary, calculates];
  }